<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn small :to="{ name: 'admin-news-en' }" elevation="0"
            >GERİ DÖN</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h2>Haber Güncelle</h2>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-form ref="form" v-model="formIsValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Başlık"
                      v-model="form.title"
                      required
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Adres Adı"
                      hint="Türkçe, özel karakter ve boşluk kullanmayınız. Örn; haber-adres-adi"
                      v-model="form.urlCode"
                      required
                      :rules="urlCodeRules"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-img
                      :src="previewImgDownloadUrl"
                      width="100%"
                      contain
                      aspect-ratio="1"
                    />
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-file-input
                      v-model="form.previewImg"
                      label="Önizleme Resmi"
                      accept=".png, .jpeg, .jpg, .gif, .svg"
                      truncate-length="50"
                      :rules="imgRules"
                      :loading="previewImgLoading"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.dateCreated"
                          label="Tarih"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.dateCreated"
                        @input="datePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ckeditor
                      :editor="editor"
                      :config="editorConfig"
                      v-model="form.text"
                      height="500"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    large
                    block
                    elevation="0"
                    color="secondary"
                    @click="submitForm()"
                    class="mr-4"
                    >KAYDET</v-btn
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    block
                    large
                    elevation="0"
                    color="secondary"
                    @click="del()"
                    >Sil</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Foto Galeri</v-card-title>
            <v-card-text>
              <v-file-input
                v-model="form.image"
                label="Yeni Fotoğraf Ekle"
                accept=".png, .jpeg, .jpg, .gif, .svg"
                truncate-length="50"
                :rules="imgRules"
                :loading="imgLoading"
                @change="uploadGalleryImg"
              />
            </v-card-text>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(img, i) in form.images"
                  :key="i"
                  cols="6"
                  sm="3"
                  md="2"
                  style="position: relative;"
                >
                  <v-img :src="img" aspect-ratio="1" />
                  <v-btn
                    fab
                    x-small
                    absolute
                    bottom
                    right
                    style="bottom: 20px; right: 20px"
                    @click="delGalleryImg(i)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  metaInfo: {
    title: "Haber Güncelle (En)"
  },

  components: {
    ckeditor: CKEditor.component,
    Confirm
  },

  data: () => {
    return {
      formIsValid: false,

      form: {
        id: "",
        title: "",
        urlCode: "",
        previewImg: null,
        text: "",
        disabled: false,
        image: null,
        images: [],
        dateCreated: new Date().toISOString().substr(0, 10)
      },

      datePicker: false,

      requiredRule: [v => !!v || "Zorunlu alan"],

      urlCodeRules: [
        v => !!v || "Zorunlu alan",
        v =>
          /^[a-z0-9-]*$/.test(v) || "Geçersiz adres adı. Örn; haber-adres-adi"
      ],

      previewImgLoading: false,

      imgLoading: false,

      imgRules: [
        v => !v || v.size < 2097152 || "Dosya boyutu 2 MB'den küçük olmalıdır."
      ],

      previewImgDownloadUrl: "",

      editor: ClassicEditor,

      editorConfig: {
        language: "tr",
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "highlight",
          "subscript",
          "superscript",
          "specialCharacters",
          "|",
          "alignment",
          "numberedList",
          "bulletedList",
          "outdent",
          "indent",
          "blockQuote",
          "|",
          "removeFormat",
          "link",
          "insertTable",
          "mediaEmbed",
          "imageInsert",
          "htmlEmbed",
          "code"
        ],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        mediaEmbed: {
          previewsInData: true
        }
      }
    };
  },

  methods: {
    async uploadPreviewImg() {
      try {
        const img = this.form.previewImg;

        const fileExt = img.name.substring(
          img.name.lastIndexOf("."),
          img.name.length
        );
        const fileName = this.form.urlCode + fileExt;

        const uploadTask = await firebase
          .storage()
          .ref()
          .child(`/news-images-en/${fileName}`)
          .put(img);

        this.fileLoading = false;

        this.form.previewImg = await uploadTask.ref.getDownloadURL();
      } catch (err) {
        this.fileLoading = false;

        this.$notify({
          title: "Hata!",
          text: `Ön izleme resmi yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
          type: "error"
        });

        console.error(
          `Ön izleme resmi yüklenemedi. ${err.code} ${err.message}`
        );
      }
    },

    async uploadPreviewImg2() {
      this.previewImgLoading = true;

      try {
        const img = this.form.previewImg;
        const fileExt = img.name.substring(
          img.name.lastIndexOf("."),
          img.name.length
        );
        const fileName = this.form.urlCode + fileExt;
        this.previewImgDownloadUrl = await this.uploadImg(img, fileName);
      } catch (err) {
        this.$notify({
          title: "Hata!",
          text: `Ön izleme resmi yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
          type: "error"
        });

        console.error(
          `Ön izleme resmi yüklenemedi. ${err.code} ${err.message}`
        );
      } finally {
        this.previewImgLoading = false;
      }
    },

    async uploadGalleryImg() {
      const img = this.form.image;

      if (!img) {
        return;
      }

      // Set img name
      const fileExt = img.name.substring(
        img.name.lastIndexOf("."),
        img.name.length
      );
      const fileName = `${this.form.urlCode}-${this.form.images.length +
        1}${fileExt}`;

      this.imgLoading = true;

      try {
        // Upload img
        const imgDownloadUrl = await this.uploadImg(img, fileName);
        this.form.images.push(imgDownloadUrl);

        // Save to db
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/news-en/${this.form.id}`)
          .update({ images: this.form.images });

        this.$notify({
          title: "İşlem Tamam!",
          text: `Fotoğraf galeriye eklendi.`,
          type: "success"
        });
      } catch (err) {
        this.$notify({
          title: "Hata!",
          text: `Fotoğraf yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
          type: "error"
        });

        console.error(`Fotoğraf yüklenemedi. ${err.code} ${err.message}`);
      } finally {
        this.imgLoading = false;
        this.form.image = null;
      }
    },

    async uploadImg(img, fileName) {
      const uploadTask = await firebase
        .storage()
        .ref()
        .child(`/news-images-en/${fileName}`)
        .put(img);
      return await uploadTask.ref.getDownloadURL();
    },

    async submitForm() {
      const form = this.$refs.form;

      if (!form.validate()) {
        return;
      }

      try {
        let news = {};

        if (this.form.previewImg) {
          // Ön izleme resmini yükle
          await this.uploadPreviewImg2();

          news = {
            title: this.form.title,
            urlCode: this.form.urlCode,
            text: this.form.text,
            dateCreated: new Date(this.form.dateCreated),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: this.form.disabled,
            previewImg: this.previewImgDownloadUrl,
            images: this.form.images
          };
        } else {
          news = {
            title: this.form.title,
            urlCode: this.form.urlCode,
            text: this.form.text,
            dateCreated: new Date(this.form.dateCreated),
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            disabled: this.form.disabled,
            images: this.form.images
          };
        }

        this.$notify({
          type: "warning",
          title: "Lütfen bekleyin!",
          text: "Haber güncelleniyor..."
        });

        await firebase
          .firestore()
          .doc(`/apps/kahev-org/news-en/${this.form.id}`)
          .update(news);

        this.$notify({
          type: "success",
          title: "İşlem Tamam!",
          text: "Haber başarıyla güncellendi."
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Haber oluşturulamadı."
        });

        console.error(err);
      }
    },

    async getNews() {
      const id = this.$route.params.id;

      try {
        const docRef = await firebase
          .firestore()
          .doc(`/apps/kahev-org/news-en/${id}`)
          .get();

        if (docRef.exists) {
          this.form.id = docRef.id;
          this.form.title = docRef.data()?.title;
          this.form.urlCode = docRef.data()?.urlCode;
          this.form.text = docRef.data()?.text;
          this.previewImgDownloadUrl = docRef.data()?.previewImg;
          this.form.dateCreated = docRef
            .data()
            ?.dateCreated.toDate()
            .toISOString()
            .substr(0, 10);
          this.form.dateUpdated = docRef.data()?.dateUpdated?.toDate();
          this.form.disabled = docRef.data()?.disabled;
          this.form.images = docRef.data()?.images;
        } else {
          console.error("Bu Id ile herhangi bir habere erişilemedi!");
        }
      } catch (err) {
        console.error(`${err.code} ${err.message}`);
      }
    },

    async del() {
      const confirm = this.$refs.confirm;

      const confirmation = await confirm.open(
        "Emin misiniz?",
        "Bu haberi silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        // Belgeyi sil
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/news-en/${this.form.id}`)
          .delete();

        // Ön izleme resmini sil
        const imgRef = firebase
          .storage()
          .refFromURL(this.previewImgDownloadUrl);
        await imgRef.delete();

        this.$router.push({ name: "admin-news-en" });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Haber silinemedi."
        });

        console.error(err);
      }
    },

    async delGalleryImg(i) {
      const confirm = this.$refs.confirm;

      const confirmation = await confirm.open(
        "Emin misiniz?",
        "Bu fotoğrafı silmek istediğinizden emin misiniz?",
        {
          color: "red"
        }
      );

      if (!confirmation) return;

      try {
        this.$notify({
          type: "warning",
          title: "Siliniyor...",
          text: "Lütfen bekleyiniz!"
        });

        // Ön izleme resmini sil
        const imgRef = firebase.storage().refFromURL(this.form.images[i]);
        await imgRef.delete();

        // Fotoğrafı diziden sil
        this.form.images.splice(i, 1);

        // Veritabanını güncelle
        await firebase
          .firestore()
          .doc(`/apps/kahev-org/news-en/${this.form.id}`)
          .update({ images: this.form.images });

        this.$notify({
          type: "success",
          title: "İşlem Tamam!",
          text: "Fotoğraf silindi."
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Fotoğraf silinemedi."
        });

        console.error(err);
      }
    }
  },

  mounted() {
    this.getNews();
  }
});
</script>
<style lang="scss" scoped></style>
